import Service from "../Service";
import Vue from "vue";
const resource = "/LotProductionCalibrated/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationLPEarning", parameters, {
            params: { requestID: requestID },
        });
    },
};