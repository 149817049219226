<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			search-input
			@rowSelected="rowSelected($event)"
			title="Lote de Producción x Certificación"
			height="auto"
			no-full
			ref="crudlpcertification"
		>
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">
						<v-col cols="6" md="4" lg="4">
							<s-date
								label="Fecha Inicio"
								v-model="filter.cDateInitial"
							></s-date>
						</v-col>
						<v-col cols="6" md="4" lg="4">
							<s-date
								label="Fecha Fin"
								v-model="filter.cDateFin"
							></s-date>
						</v-col>
						<v-col cols="12" md="4" lg="4">
							<s-select-definition
								:def="1230"
								label="Planta Procesadora"
								v-model="filter.TypeProcessingPlant"
								clearable
							/>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="6" md="4" lg="3">
							<s-select-definition
								clearable
								:def="1172"
								label="Tipo Cultivo"
								v-model="filter.TypeCrop"
							/>
						</v-col>
						<v-col cols="6" md="4" lg="3">
							<s-select-definition
								clearable
								:def="1173"
								label="Cultivo"
								v-model="filter.TypeCultive"
							/>
						</v-col>

						<v-col cols="12" md="4" lg="3">
							<s-select-variety
								clearable
								:cultiveID="filter.TypeCultive"
								label="Variedad"
								full
								v-model="filter.VrtID"
							/>
						</v-col>

                        <v-col cols="12" md="12" lg="3">
								<s-select-type-certification
									clearable
									noDefault
									label="Certificacion"
									v-model="filter.TcrID"
								></s-select-type-certification>
							</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:RcfState="{ row }">
				<v-chip x-small :color="row.TypeStateColor">
					{{ row.TypeStateName }}
				</v-chip>
			</template>

			<template v-slot:RcfID="{ row }">
				<v-chip x-small :color="row.TypeStateColor">
					{{ row.RcfID }} - {{row.RcfCode}}
				</v-chip>
			</template>

			<template v-slot:TypeAlertName="{ row }">
				<v-chip x-small :color="row.TypeAlertColor">
					{{ row.TypeAlertName }}
				</v-chip>
			</template>

			<template v-slot:ViewCert="{ row }">
				<v-btn x-small color="info" block outlined @click="clickView(row)">
					<v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
				</v-btn>
			</template>
			<template v-slot:ViewCert="{ row }">
				<v-btn x-small color="info" block outlined @click="clickView(row)">
					<v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
				</v-btn>
			</template>
		</s-crud>

		<v-dialog
			v-if="openDialogView"
			v-model="openDialogView"
			persistent
			style="display: contents"
			width="70%"
		>
			<s-certification-lot :FltID="FltID" @close="closeCert()" />
		</v-dialog>

	</div>
</template>

<script>
	/* import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService"; */

	//Service
	import _sLPEarningCertification from "../../../services/FreshProduction/LPEarningCertification.js";

	//Components
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import SCertificationLot from "@/components/TecnicalAssistence/sCertificationLot.vue";
    import sSelectTypeCertification from "@/components/Utils/Certification/sSelectTypeCertification.vue";

	export default {
		components: {
			SSelectVariety,
			SCertificationLot,
            sSelectTypeCertification
		},

		data() {
			return {
	
				FltID: 0,
				openDialogView: false,
				filter: {},
				config: {
					service: _sLPEarningCertification,
					model: {
						RcfID: "ID",
						RcfDateReceptionSurveillance: "date",
						RcfState: "",
						ViewCert: "",
						TypeAlertName: "",
					},
					headers: [
						{ text: "View", value: "ViewCert", width: 70 },
						{ text: "Estado", value: "RcfState", width: 20 },
						{ text: "Nro. Lote.", value: "RcfID", width: 100 },
						{
							text: "Fec. Recep. Vigilancia.",
							value: "RcfDateReceptionSurveillance",
							width: 140,
						},
						{ text: "Certificación", value: "Certifications", width: 260 },
						{
							text: "Cant. Jab/Bines",
							value: "AmountJabasWeigh",
							width: 160,
						},
						{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
						{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
						{ text: "Variedad", value: "VrtName", width: 160 },
						{ text: "Lote", value: "FltName", width: 100 },
						{
							text: "Tipo Servicio",
							value: "TypeServiceName",
							width: 100,
						},
						{ text: "Fundo", value: "FagName", width: 260 },
						{ text: "Productor", value: "PrdCardName", width: 260 },
						{ text: "Cod. Senasa", value: "FltCodSenasa", width: 160 },
						{
							text: "Guia Cosecha",
							value: "RcfNumberHarvestGuide",
							width: 160,
						},
						

						{
							text: "Peso Muestreado (KG)",
							value: "WbbGrossWeight",
							width: 160,
						},
						{
							text: "Peso Promedio Fruta (KG)",
							value: "WbbWeightAverage",
							width: 160,
						},
						{
							text: "Peso Total Lote (KG)",
							value: "WbbNetWeight",
							width: 160,
						},

						{
							text: "Planta Procesdora",
							value: "TypeProcessingPlantName",
							width: 160,
						},
						{ text: "Empacadora", value: "TypeBalerName", width: 160 },
						{
							text: "Zona Ubicacion",
							value: "TypeZonLocationName",
							width: 160,
						},
						{ text: "Alerta", value: "TypeAlertName", width: 100 },
						{
							text: "Observaciones",
							value: "RcfObservations",
							width: 260,
						},
					],
				},
                items: [],
			};
		},

		methods: {

			clickView(val) {
				this.FltID = val.FltID;
				this.openDialogView = true;
			},

			closeCert() {
				this.openDialogView = false;
			},

			rowSelected(item) {
				if(item.length > 0){
					if(item !== undefined){
						
						this.items = item[0];
					}
				}
				
				
			},

		},
	};
</script>
